html,
body {
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}
